<template>
  <v-dialog
    v-model="show"
    v-show="false"
    max-width="400"
    persistent    
  >
    <v-card dark>
      <v-card-title class="colorCustom1 justify-center">Imprimiendo..</v-card-title>
      <v-card-text class="colorCustom2--text font-weight-bold grey lighten-2 text-center justify-center pt-16 pb-16">
        <h3>Gracias por usar el sistema de Autogestión Tributaria de MVL.</h3> 
      </v-card-text>    
      <v-card-actions class="justify-center grey lighten-2">        
        <botonFinalizar />
      </v-card-actions>
    </v-card>
    <iframe v-show="false" :src="url" width="650" height="900" id="pdf_tributo" @load="imprimir"/> 
  </v-dialog>
</template>
<script>
  export default {
  components: {
    botonFinalizar: () => import('./botonFinalizar.vue'),
  },     
  data () {
      return {
        url: '',  
        load_count: 0,     
      }
    },
    props: {
      value: Boolean,
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
    methods: {
      setForm: async function (url) {
        // xhr.open('GET', 'http://www.test.desarrollorusoft.com.ar/test/ua0NlPG000OBo27iPYKI2WALpifvnwMBmOuQCYyx.pdf', true)
          var xhr = new XMLHttpRequest()
          xhr.open('GET', url, true)
          xhr.responseType = "arraybuffer"
          xhr.onreadystatechange = function() {
              if (xhr.readyState == 4 && xhr.status == 200){
                var file = new Blob([xhr.response], {type: 'application/pdf'})
                this.url = URL.createObjectURL(file)    
              } 
            }.bind(this)

          xhr.send()  
      },
      imprimir: function () { 
        this.load_count++;
        if(this.load_count >= 3){
        var frm = document.getElementById('pdf_tributo').contentWindow
        frm.focus()
        frm.print()
      }
    },
    }
  }
</script>
